import compact from "lodash/compact";
import omit from "lodash/omit";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useUpdateEffect } from "react-use";
import { Nullable, queryTypes, useQueryStates, UseQueryStatesKeysMap } from "next-usequerystate";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";
import { MRT_SortingState } from "data-grid";

import {
  calendarDateSerializer,
  CalendarDate,
  startOfMonth,
  endOfMonth,
  parseDate,
  CalendarView,
  isSameDateValue,
} from "@puzzle/utils";
import { RangePresets, RangePresetLists, RangePreset, GroupBy } from "@puzzle/ui";
import { useSelf } from "components/users/useSelf";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useAppRouter } from "lib/useAppRouter";

import Analytics from "lib/analytics/analytics";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import {
  CardFragment,
  CategoryFragment,
  FinancialInstitutionFragment,
  TransactionSortOrder,
  CashFlow,
  IntegrationType,
  TransactionFilterBy,
  VendorFragment,
  VendorFragmentDoc,
  RecurrencePeriod,
  TransactionDetailActorType,
} from "graphql/types";
import {
  TransactionPageAccountFragment,
  BasicTransactionFragment,
  GetTransactionsQuery,
  GetTransactionsQueryVariables,
  useGetImpactfulTransactionsQuery,
} from "./graphql.generated";
import { useSearchOptions } from "./hooks/useSearchOptions";
import { useTransactions } from "./hooks/useTransactions";
import { useVendors } from "components/common/hooks/vendors";

import { MANUAL_INTEGRATION_TYPES } from "./AsteriskTooltip";
import { noVendor } from "components/transactions/vendors/VendorSearch";
import { sanitizeAmountFiltersForQuery } from "components/common/AmountMenu";
import {
  ClassFilterOption,
  useClassificationFilters,
} from "./ExtendedFilter/useClassificationsFilter";
import { useGetTransactionListAllTimeRange } from "components/transactions/utils";
import { DEFAULT_PAGE_SIZE } from "./TransactionsPaginated";
import {
  useCategorizableAccounts,
  CategorizableAccount,
} from "components/common/hooks/useCategorizableAccounts";

// Integration types that don't ingest data
// These should be excluded from transaction filters
const NON_DATA_INTEGRATION_TYPES = [IntegrationType.Slack];

/**
 * TODO extract the cc modal from here - the Inbox requires this provider just to support the cc
 * offset modal, seems like an unnecessary amount of code to bring in just for the cc offset modal
 */
export type PageTotals = {
  count: number;
  gain: number;
  loss: number;
  sum: number;
  assignedToMeCount: number;
};

export enum CashFlowFriendly {
  MoneyIn = "in",
  MoneyOut = "out",
}

export enum FilterStatus {
  NotCategorized = "uncategorized",
  NotFinalized = "unfinalized",
  Finalized = "finalized",
  NotLinkedBankTransfers = "NotLinkedBankTransfers",
  NotLinkedCCPayments = "NotLinkedCCPayments",
  NotLinkedProcessorTransfers = "NotLinkedProcessorTransfers",
}

const DEFAULT_RANGE_PRESET = RangePresets.Last90Days;
const DEFAULT_RANGE = DEFAULT_RANGE_PRESET.range!();

export type ClassFilter = {
  withSomeOfClassSegmentIds: string[];
  withNoneOfClassIds: string[];
};
const DEFAULT_CLASS_FILTERS: ClassFilter = {
  withSomeOfClassSegmentIds: [],
  withNoneOfClassIds: [],
};
// TODO Probably break this up or group it
// Can break filter state management into its own context
const TransactionsPageContext = React.createContext<{
  hasMore: boolean;
  refetch: () => Promise<ApolloQueryResult<GetTransactionsQuery>>;
  loading: boolean;
  companyId: string;
  fetchNextPage: () => void;
  setActiveTransaction: (id: string | null) => void;
  activeTransactionId: string | null;
  setActiveTransactionId: React.Dispatch<React.SetStateAction<string | null>>;
  cardsById: Partial<Record<string, CardFragment>>;
  categoriesByPermaKey?: Partial<Record<string, CategoryFragment>>;
  accountsById: Partial<Record<string, TransactionPageAccountFragment>>;
  transactions: BasicTransactionFragment[];
  transactionsByPage: BasicTransactionFragment[][] | null;
  categories: CategoryFragment[];
  cards: CardFragment[];
  selectedCategories: CategoryFragment[];
  selectedCategorizableAccounts: CategorizableAccount[];
  selectedVendors: VendorFragment[];
  selectedClassFilters: ClassFilterOption[];
  classificationOptions: ClassFilterOption[];
  institutions: FinancialInstitutionFragment[];
  totals: PageTotals;
  filter: FilterState;
  filterCount: number;
  setFilter: (f: Partial<FilterState>) => void;
  setFilterCount: (count: number) => void;
  resetFilter: () => void;
  removeAccount: (id: string) => void;
  removeCategory: (id: string) => void;
  removeCategorizableAccount: (id: string) => void;
  removeVendor: (id: string) => void;
  removeCard: (id: string) => void;
  removeClassFilters: (id: string) => void;
  removeRecurrence: (id: RecurrencePeriod) => void;
  sortOptions: MRT_SortingState;
  setSortOptions: React.Dispatch<React.SetStateAction<MRT_SortingState>>;

  rangePresets: RangePreset[];
  fetchAllTimeRange: () => Promise<void>;
  allTimeRange: CalendarDate[];
  allTimeRangeCalled: boolean;
  allTimeRangeLoading: boolean;

  impactfulTransactionsMetadata: {
    nextImpactfulRange: [CalendarDate, CalendarDate] | null;
    isFullyCategorized: boolean;
    isCurrentViewCategorized: boolean;
    loading: boolean;
  };

  queryVariables: GetTransactionsQueryVariables;
} | null>(null);

export type QueryFilterState = {
  start: CalendarDate;
  end: CalendarDate;
  rangePreset: RangePreset;
  descriptor: string;
  descriptorExact: string;
  pageSize: number;
  cashFlow: CashFlowFriendly;
  classesFilter: ClassFilter;
  minAmount: number;
  maxAmount: number;
  accountIds: string[];
  ledgerCoaKeys: string[];
  ledgerAccountDisplayIds: string[];
  vendorIds: string[];
  cardIds: string[];
  status: FilterStatus;
  assignedToMe: boolean;
  assignedToOthers: boolean;
  posted: boolean;
  hasDocumentation: boolean;
  sortBy: TransactionSortOrder;
  isManual: boolean;
  showAccrualDate: boolean;
  showAvailableDate: boolean;
  recurrences: RecurrencePeriod[];
  categorizedByAI: boolean;
  // NOTE: isLinked being true doesn't work as expected.
  // It only works for false/null
  isLinked: boolean;
  isBillPayment: boolean;
  hasSplits: boolean;
};
type OptionalFields =
  | "rangePreset"
  | "cashFlow"
  | "minAmount"
  | "maxAmount"
  | "status"
  | "assignedToMe"
  | "assignedToOthers"
  | "posted"
  | "hasDocumentation"
  | "isManual"
  | "showAccrualDate"
  | "showAvailableDate"
  | "recurrences"
  | "categorizedByAI"
  | "isLinked"
  | "isBillPayment"
  | "hasSplits"
  | "descriptorExact";
export type FilterState = Omit<QueryFilterState, OptionalFields | "sortBy"> &
  Nullable<Pick<QueryFilterState, OptionalFields>>;

const getStoredPageSize = () => {
  if (typeof window === "undefined") {
    return DEFAULT_PAGE_SIZE; // Return default value during SSR
  }
  const stored = localStorage.getItem("pz:transactions-mrt-page-size");
  return stored ? parseInt(stored, 10) : DEFAULT_PAGE_SIZE;
};

export const defaultFilter: FilterState = {
  start: DEFAULT_RANGE[0],
  end: DEFAULT_RANGE[1],
  rangePreset: DEFAULT_RANGE_PRESET,
  descriptor: "",
  descriptorExact: null,
  cashFlow: null,
  minAmount: null,
  maxAmount: null,
  ledgerCoaKeys: [],
  ledgerAccountDisplayIds: [],
  vendorIds: [],
  accountIds: [],
  classesFilter: DEFAULT_CLASS_FILTERS,
  cardIds: [],
  status: null,
  assignedToMe: null,
  assignedToOthers: null,
  posted: null,
  hasDocumentation: null,
  isManual: null,
  showAccrualDate: null,
  showAvailableDate: null,
  recurrences: [],
  pageSize: getStoredPageSize(),
  categorizedByAI: null,
  // NOTE: isLinked being true doesn't work as expected.
  // It only works for false/null
  isLinked: null,
  isBillPayment: null,
  hasSplits: null,
};

const useImpactfulTransactions = ({
  queryVariables,
  uncategorizedPermaKeys,
  transactions,
  transactionsByPage,
  filter,
  loadingTransactions,
  loadingSearchOptions,
}: {
  queryVariables: GetTransactionsQueryVariables;
  filter: FilterState;
  uncategorizedPermaKeys: string[] | undefined;
  transactions: BasicTransactionFragment[];
  transactionsByPage: BasicTransactionFragment[][] | null;
  loadingTransactions: boolean;
  loadingSearchOptions: boolean;
}) => {
  const { company } = useActiveCompany<true>();

  // For now these round the boundaries to the neighboring months.
  // If you select a partial month, isFullyCategorized won't be totally accurate.
  // This is all too complex for the client anyway.
  const startExclusive = useMemo(
    () =>
      queryVariables.filterBy?.startDate
        ? startOfMonth(
            parseDate(queryVariables.filterBy.startDate).subtract({
              days: 1,
            })
          )
        : undefined,
    [queryVariables.filterBy?.startDate]
  );
  const endExclusive = useMemo(
    () =>
      queryVariables.filterBy?.endDate
        ? endOfMonth(parseDate(queryVariables.filterBy.endDate)).add({ days: 1 })
        : undefined,
    [queryVariables.filterBy?.endDate]
  );

  const { data, loading } = useGetImpactfulTransactionsQuery({
    skip: loadingSearchOptions || !uncategorizedPermaKeys,
    variables: {
      companyId: company.id,
      uncategorizedKeys: uncategorizedPermaKeys!,

      pastUncategorizedStartDate: startExclusive?.subtract({ months: 2 }).toString(),
      pastUncategorizedEndDate: endExclusive?.toString(),

      futureUncategorizedEndDate: endExclusive?.add({ months: 2 }).toString(),
      futureUncategorizedStartDate: endExclusive?.toString(),
    },
  });

  const { pastUncategorizedTransactions, futureUncategorizedTransactions } = data?.company || {};

  const nextImpactfulRange = useMemo<[CalendarDate, CalendarDate] | null>(() => {
    const node =
      pastUncategorizedTransactions?.nodes?.[0] || futureUncategorizedTransactions?.nodes?.[0];
    if (!node) {
      return null;
    }

    const date = parseDate(node.date);
    return [startOfMonth(date), endOfMonth(date)];
  }, [futureUncategorizedTransactions, pastUncategorizedTransactions]);

  const isFullyCategorized = useMemo(() => {
    return (
      !loading &&
      pastUncategorizedTransactions?.nodes.length === 0 &&
      futureUncategorizedTransactions?.nodes.length === 0
    );
  }, [
    futureUncategorizedTransactions?.nodes.length,
    pastUncategorizedTransactions?.nodes.length,
    loading,
  ]);

  const isCurrentViewCategorized = useMemo(() => {
    if (
      filter.status !== FilterStatus.NotCategorized ||
      loadingTransactions ||
      loadingSearchOptions
    ) {
      return false;
    }

    // TODO Do I need to check splits...?
    return transactions.every((x) => !uncategorizedPermaKeys?.includes(x.detail.category.permaKey));
  }, [
    filter.status,
    loadingSearchOptions,
    loadingTransactions,
    transactions,
    uncategorizedPermaKeys,
  ]);

  return useMemo(
    () => ({
      nextImpactfulRange,
      isFullyCategorized,
      isCurrentViewCategorized,
      loading: loadingSearchOptions || loadingTransactions,
    }),
    [
      isCurrentViewCategorized,
      isFullyCategorized,
      loadingSearchOptions,
      loadingTransactions,
      nextImpactfulRange,
    ]
  );
};

export const TransactionsPageProvider = ({ children }: { children?: React.ReactNode }) => {
  const client = useApolloClient();

  const { self } = useSelf();
  const { company } = useActiveCompany<true>();
  const companyId = company.id;
  const { categorizableAccountsByDisplayId, categorizableAccounts } = useCategorizableAccounts();
  const { goToTransaction } = useAppRouter();

  const [activeTransactionId, setActiveTransactionId] = useState<string | null>(null);
  const setActiveTransaction = useCallback(
    (id: string | null) => {
      if (id) {
        setActiveTransactionId(id);
        goToTransaction(id, true);
      } else {
        setActiveTransactionId(null);
      }
    },
    [goToTransaction]
  );

  const { fetchAllTimeRange, allTimeRange, allTimeRangeCalled, allTimeRangeLoading } =
    useGetTransactionListAllTimeRange(company);
  const rangePresets = useMemo(() => {
    const allTimePreset = RangePresets.getAllTimePreset(
      allTimeRange[0],
      GroupBy.Total,
      CalendarView.Day,
      allTimeRange[1]
    );
    return [...RangePresetLists.Transactions, allTimePreset];
  }, [allTimeRange]);

  const rangePresetSerializer = useMemo(() => {
    return {
      parse: (key: string) => rangePresets.find((x) => x.key === key) || null,
      serialize: (preset: RangePreset) => preset.key,
    };
  }, [rangePresets]);

  const [queryFilter, _setQueryFilter] = useQueryStates<
    UseQueryStatesKeysMap<Omit<QueryFilterState, "recurrences" | "pageSize">> // We omit recurrences and pageSize from the URL query string
  >({
    start: calendarDateSerializer,
    end: calendarDateSerializer,
    rangePreset: rangePresetSerializer,
    descriptor: queryTypes.string,
    minAmount: queryTypes.float,
    maxAmount: queryTypes.float,
    accountIds: queryTypes.array(queryTypes.string, ".").withDefault([]),
    vendorIds: queryTypes.array(queryTypes.string, ".").withDefault([]),
    classesFilter: queryTypes.json<ClassFilter>().withDefault(DEFAULT_CLASS_FILTERS),
    ledgerCoaKeys: queryTypes.array(queryTypes.string, ".").withDefault([]),
    ledgerAccountDisplayIds: queryTypes.array(queryTypes.string, ".").withDefault([]),
    cardIds: queryTypes.array(queryTypes.string, ".").withDefault([]),
    assignedToMe: queryTypes.boolean,
    assignedToOthers: queryTypes.boolean,
    status: queryTypes.stringEnum<FilterStatus>(Object.values(FilterStatus)),
    cashFlow: queryTypes.stringEnum<CashFlowFriendly>(Object.values(CashFlowFriendly)),
    posted: queryTypes.boolean,
    hasDocumentation: queryTypes.boolean,
    sortBy: queryTypes.stringEnum<TransactionSortOrder>(Object.values(TransactionSortOrder)),
    isManual: queryTypes.boolean,
    showAccrualDate: queryTypes.boolean,
    showAvailableDate: queryTypes.boolean,
    categorizedByAI: queryTypes.boolean,
    // NOTE: isLinked being true doesn't work as expected.
    // It only works for false/null
    isLinked: queryTypes.boolean,
    isBillPayment: queryTypes.boolean,
    hasSplits: queryTypes.boolean,
    descriptorExact: queryTypes.string,
  });

  // Would a map be better...?
  const [sortOptions, setSortOptions] = useState<MRT_SortingState>(() => {
    switch (queryFilter.sortBy) {
      case TransactionSortOrder.DateAsc:
        return [{ desc: false, id: "date" }];
      case TransactionSortOrder.DateDesc:
        return [{ desc: true, id: "date" }];
      case TransactionSortOrder.AmountAsc:
        return [{ desc: false, id: "amount" }];
      case TransactionSortOrder.AmountDesc:
        return [{ desc: true, id: "amount" }];
      case TransactionSortOrder.DescriptionAsc:
        return [{ desc: false, id: "descriptor" }];
      case TransactionSortOrder.DescriptionDesc:
        return [{ desc: true, id: "descriptor" }];
      case TransactionSortOrder.VendorAsc:
        return [{ desc: false, id: "vendor_customer" }];
      case TransactionSortOrder.VendorDesc:
        return [{ desc: true, id: "vendor_customer" }];
      default:
        return [];
    }
  });
  const sortBy = useMemo(() => {
    const { id, desc } = sortOptions?.[0] || {};
    switch (id) {
      case "date":
        return desc ? TransactionSortOrder.DateDesc : TransactionSortOrder.DateAsc;
      case "amount":
        return desc ? TransactionSortOrder.AmountDesc : TransactionSortOrder.AmountAsc;
      case "descriptor":
        return desc ? TransactionSortOrder.DescriptionDesc : TransactionSortOrder.DescriptionAsc;
      case "vendor_customer":
        return desc ? TransactionSortOrder.VendorDesc : TransactionSortOrder.VendorAsc;
      default:
        return undefined;
    }
  }, [sortOptions]);

  // setQueryFilter is not memoized properly (idk it's an odd module). Memoize once so we don't forget
  const setQueryFilter = useMemo(
    () => _setQueryFilter,
    // eslint-disable-next-line
    []
  );

  const [filter, _setFilter] = useState<FilterState>(() => {
    const rangeFromPreset = queryFilter.rangePreset?.range?.();
    return {
      minAmount: queryFilter.minAmount ?? defaultFilter.minAmount,
      maxAmount: queryFilter.maxAmount ?? defaultFilter.maxAmount,
      status: queryFilter.status ?? defaultFilter.status,
      assignedToMe: queryFilter.assignedToMe ?? defaultFilter.assignedToMe,
      assignedToOthers: queryFilter.assignedToOthers ?? defaultFilter.assignedToOthers,
      descriptor: queryFilter.descriptor ?? defaultFilter.descriptor,
      ledgerCoaKeys: queryFilter.ledgerCoaKeys ?? defaultFilter.ledgerCoaKeys,
      ledgerAccountDisplayIds:
        queryFilter.ledgerAccountDisplayIds ?? defaultFilter.ledgerAccountDisplayIds,
      accountIds: queryFilter.accountIds ?? defaultFilter.accountIds,
      vendorIds: queryFilter.vendorIds ?? defaultFilter.vendorIds,
      cardIds: queryFilter.cardIds ?? defaultFilter.cardIds,
      classesFilter: queryFilter.classesFilter ?? defaultFilter.classesFilter,
      posted: queryFilter.posted ?? defaultFilter.posted,
      start: queryFilter.start ?? rangeFromPreset?.[0] ?? defaultFilter.start,
      end: queryFilter.end ?? rangeFromPreset?.[1] ?? defaultFilter.end,
      rangePreset:
        queryFilter.rangePreset || queryFilter.start || queryFilter.end
          ? queryFilter.rangePreset
          : defaultFilter.rangePreset,
      pageSize: defaultFilter.pageSize,
      cashFlow: queryFilter.cashFlow ?? defaultFilter.cashFlow,
      hasDocumentation: queryFilter.hasDocumentation ?? defaultFilter.hasDocumentation,
      isManual: queryFilter.isManual ?? defaultFilter.isManual,
      showAccrualDate: queryFilter.showAccrualDate ?? defaultFilter.showAccrualDate,
      showAvailableDate: queryFilter.showAvailableDate ?? defaultFilter.showAvailableDate,
      recurrences: defaultFilter.recurrences,
      categorizedByAI: queryFilter.categorizedByAI ?? defaultFilter.categorizedByAI,
      // NOTE: isLinked being true doesn't work as expected.
      // It only works for false/null
      isLinked: queryFilter.isLinked === false ? false : defaultFilter.isLinked,
      isBillPayment: queryFilter.isBillPayment ?? defaultFilter.isBillPayment,
      descriptorExact: queryFilter.descriptorExact ?? defaultFilter.descriptorExact,
      hasSplits: queryFilter.hasSplits ?? defaultFilter.hasSplits,
    };
  });

  const [filterCount, _setFilterCount] = useState(0);

  useUpdateEffect(() => {
    setQueryFilter(
      {
        ...omit(filter, ["recurrences", "pageSize"]), // don't need these in the URL
        // Use null to remove the query param
        // There's probably a DRYer way
        vendorIds: filter.vendorIds && filter.vendorIds?.length > 0 ? filter.vendorIds : null,
        accountIds: filter.accountIds && filter.accountIds?.length > 0 ? filter.accountIds : null,
        classesFilter:
          (filter.classesFilter && filter.classesFilter?.withSomeOfClassSegmentIds?.length > 0) ||
          filter.classesFilter?.withNoneOfClassIds?.length > 0
            ? filter.classesFilter
            : null,
        ledgerCoaKeys:
          filter.ledgerCoaKeys && filter.ledgerCoaKeys?.length > 0 ? filter.ledgerCoaKeys : null,
        ledgerAccountDisplayIds:
          filter.ledgerAccountDisplayIds && filter.ledgerAccountDisplayIds?.length > 0
            ? filter.ledgerAccountDisplayIds
            : null,
        cardIds: filter.cardIds && filter.cardIds?.length > 0 ? filter.cardIds : null,
        descriptor: filter.descriptor || null,
        cashFlow: filter.cashFlow || null,
      },
      { scroll: false, shallow: true }
    );
  }, [filter, setQueryFilter]);

  useUpdateEffect(() => {
    setQueryFilter({ sortBy: sortBy ?? null }, { scroll: false, shallow: true });
  }, [sortBy]);

  const setFilter = useCallback((filter: Partial<FilterState>) => {
    _setFilter((previous) => ({ ...previous, ...filter }));
  }, []);

  const setFilterCount = useCallback((count: number) => {
    _setFilterCount(count);
  }, []);

  // Analytics
  useEffect(() => {
    if (filter.status === FilterStatus.NotCategorized) {
      Analytics.impactfulTransactionsFilterEnabled();
    }
  }, [filter.status]);

  useEffect(() => {
    // if we are on the all time range ensure that the
    // range is set to the actual all time range
    // this is especially important because the all time range preset
    // user selection is maintained as a url param
    if (filter.rangePreset?.key === "allTime") {
      if (!allTimeRangeCalled) {
        fetchAllTimeRange();
        return;
      }

      if (allTimeRangeLoading) {
        return;
      }

      const [allTimeStart, allTimeEnd] = allTimeRange;
      if (
        !isSameDateValue(filter.start, allTimeStart) ||
        !isSameDateValue(filter.end, allTimeEnd)
      ) {
        setFilter({
          start: allTimeStart,
          end: allTimeEnd,
        });
      }
    }
  }, [setFilter, filter.rangePreset, filter.start, filter.end, allTimeRange]);

  useUpdateEffect(() => {
    Analytics.transactionsTableCashflowChanged({
      cashFlow: filter.cashFlow ?? "all",
    });
  }, [filter.cashFlow]);

  const resetFilter = useCallback(() => {
    setFilter(
      omit(
        defaultFilter,
        // hmm these don't show up as tags, so don't reset
        // maybe we should reset an explicit list instead of opting out
        ["start", "end", "rangePreset"]
      )
    );
  }, [setFilter]);

  const {
    cards,
    cardsById,
    categories,
    categoriesByPermaKey,
    uncategorizedPermaKeys,
    institutions,
    accountsById,
    loading: loadingSearchOptions,
  } = useSearchOptions();

  const selectedCategories: CategoryFragment[] = useMemo(() => {
    if (isPosthogFeatureFlagEnabled(FeatureFlag.GroupCategoriesByAccount)) {
      return [];
    }
    return compact(filter.ledgerCoaKeys.map((id) => categoriesByPermaKey?.[id]));
  }, [categoriesByPermaKey, filter.ledgerCoaKeys]);

  const selectedCategorizableAccounts: CategorizableAccount[] = useMemo(() => {
    if (!isPosthogFeatureFlagEnabled(FeatureFlag.GroupCategoriesByAccount)) {
      return [];
    }

    if (filter.ledgerCoaKeys?.length && !filter.ledgerAccountDisplayIds?.length) {
      // support for legacy filters, could still be around from sharing links
      // or from links elsewhere in the app that eventually might better do linking
      // by diplayId
      const ret: CategorizableAccount[] = [];
      const coaKeySet = new Set(filter.ledgerCoaKeys);
      categorizableAccounts.forEach((ca) => {
        for (const key of ca.coaKeys) {
          if (coaKeySet.has(key)) {
            ret.push(ca);
            break;
          }
        }
      });
      setFilter({
        ledgerCoaKeys: [],
        ledgerAccountDisplayIds: ret.map((r) => r.displayId),
      });
      return ret;
    }

    if (filter?.ledgerCoaKeys.length && filter.ledgerAccountDisplayIds?.length) {
      // don't need to convert, just use the diplsyIds_
      setFilter({
        ledgerCoaKeys: [],
      });
    }
    return compact(
      filter.ledgerAccountDisplayIds.map((id) => categorizableAccountsByDisplayId?.[id])
    );
  }, [
    categorizableAccountsByDisplayId,
    filter.ledgerAccountDisplayIds,
    filter.ledgerCoaKeys,
    categorizableAccounts,
    setFilter,
  ]);

  const initialVendorsFetched = useRef<boolean>(filter.vendorIds.length === 0);
  // Fetches specific vendors to show their tags and preselect the dropdown
  const { data: vendorsData } = useVendors({
    fetchPolicy: "cache-first",
    skip: initialVendorsFetched.current,

    variables: {
      filterBy: { ids: filter.vendorIds },
    },
  });

  if (vendorsData) {
    initialVendorsFetched.current = true;
  }

  const selectedVendors = useMemo(
    () =>
      compact(
        filter.vendorIds.map((id) => {
          if (id === noVendor.id) {
            return noVendor;
          }
          return client.readFragment<VendorFragment>({
            id: `Vendor:${id}`,
            fragmentName: "vendor",
            fragment: VendorFragmentDoc,
          });
        })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      client,
      filter.vendorIds,
      // Included since it inherently updates readFragment
      vendorsData,
      noVendor,
    ]
  );

  const { classificationOptions, selectedClassFilters, removeClassFilterFormatter } =
    useClassificationFilters(filter.classesFilter);

  const removeClassFilters = useCallback(
    (classId: string) => {
      setFilter(removeClassFilterFormatter(classId));
    },
    [setFilter, removeClassFilterFormatter]
  );

  // QUERY VARIABLES
  const queryVariables = useMemo<
    Omit<GetTransactionsQueryVariables, "filterBy"> & { filterBy: TransactionFilterBy }
  >(() => {
    // TODO the query could just ignore empty arrays...?
    let selectedCategories = filter.ledgerCoaKeys.length > 0 ? filter.ledgerCoaKeys : undefined;
    if (isPosthogFeatureFlagEnabled(FeatureFlag.GroupCategoriesByAccount)) {
      selectedCategories = [];
      filter.ledgerAccountDisplayIds.forEach((displayId) => {
        if (categorizableAccountsByDisplayId[displayId]) {
          categorizableAccountsByDisplayId[displayId].coaKeys.forEach((coaKey) => {
            selectedCategories?.push(coaKey);
          });
        }
      });
    }
    // TODO should NotCategorized append instead of overriding?
    const ledgerCoaKeys =
      filter.status === FilterStatus.NotCategorized && uncategorizedPermaKeys
        ? uncategorizedPermaKeys
        : selectedCategories;

    const amountFilters = sanitizeAmountFiltersForQuery(filter);

    let integrationTypesFilter: IntegrationType[] | null = null;
    if (typeof filter.isManual === "boolean") {
      integrationTypesFilter = filter.isManual
        ? MANUAL_INTEGRATION_TYPES
        : Object.values(IntegrationType).filter(
            (type) =>
              !MANUAL_INTEGRATION_TYPES.includes(type) && !NON_DATA_INTEGRATION_TYPES.includes(type)
          );
    }

    return {
      companyId,
      page: {
        count: filter.pageSize,
        after: null,
      },
      sortBy,
      filterBy: {
        ...amountFilters,
        [filter.showAvailableDate ? "availableOnStartDate" : "startDate"]: filter.start.toString(),
        [filter.showAvailableDate ? "availableOnEndDate" : "endDate"]: filter.end.toString(),
        descriptor: filter.descriptor || undefined, // strip out blank string
        cashFlow:
          filter.cashFlow === CashFlowFriendly.MoneyIn
            ? CashFlow.MoneyIn
            : filter.cashFlow === CashFlowFriendly.MoneyOut
              ? CashFlow.MoneyOut
              : null,
        ledgerCoaKeys,
        cardIds: filter.cardIds.length > 0 ? filter.cardIds : undefined,
        accountIds: filter.accountIds.length > 0 ? filter.accountIds : undefined,
        vendorIds: filter.vendorIds.length > 0 ? filter.vendorIds : undefined,
        classesFilter:
          filter.classesFilter.withNoneOfClassIds.length > 0 ||
          filter.classesFilter.withSomeOfClassSegmentIds.length > 0
            ? filter.classesFilter
            : undefined,
        finalized:
          filter.status === FilterStatus.Finalized
            ? true
            : filter.status === FilterStatus.NotFinalized
              ? false
              : undefined,
        assignedTo: filter.assignedToMe ? self!.id : undefined,
        // assignedToOthers api accepts the ID to exclude
        assignedToOthers: filter.assignedToOthers ? self!.id : undefined,
        posted: filter.posted ? filter.posted : undefined,
        hasDocumentation: filter.hasDocumentation ?? undefined,
        integrationTypes: integrationTypesFilter !== null ? integrationTypesFilter : undefined,
        recurrences: filter.recurrences ?? undefined,
        actorType: filter.categorizedByAI ? TransactionDetailActorType.AiActor : undefined,
        // NOTE: isLinked being true doesn't work as expected.
        // It only works for false/null
        isLinked: filter.isLinked === false ? filter.isLinked : null,
        isBillPayment: filter.isBillPayment ?? undefined,
        descriptorExact: filter.descriptorExact ?? undefined,
        hasSplits: filter.hasSplits ?? undefined,
      },
    };
  }, [companyId, sortBy, filter, uncategorizedPermaKeys, self, categorizableAccountsByDisplayId]);

  // only need search options for query if a category filter is applied
  const skipTransactionsQuery = filter.ledgerCoaKeys.length === 0 ? false : loadingSearchOptions;

  const {
    transactions: maybeTransactions,
    totals,
    loading: loadingTransactions,
    fetchNextPage,
    hasMore,
    refetch,
    transactionsByPage: maybeTransactionsByPage,
  } = useTransactions(queryVariables, { skip: skipTransactionsQuery });
  const loading = loadingTransactions || loadingSearchOptions;

  const transactions = useMemo(() => {
    return maybeTransactions || [];
  }, [maybeTransactions]);

  const transactionsByPage = useMemo(() => {
    return maybeTransactionsByPage || [[]];
  }, [maybeTransactionsByPage]);

  const removeAccount = useCallback(
    (id: string) => {
      setFilter({
        accountIds: filter.accountIds.filter((x) => x !== id),
      });
    },
    [filter.accountIds, setFilter]
  );

  const removeCategory = useCallback(
    (id: string) => {
      setFilter({
        ledgerCoaKeys: filter.ledgerCoaKeys.filter((x) => x !== id),
      });
    },
    [filter.ledgerCoaKeys, setFilter]
  );

  const removeCategorizableAccount = useCallback(
    (id: string) => {
      setFilter({
        ledgerAccountDisplayIds: filter.ledgerAccountDisplayIds.filter((x) => x !== id),
      });
    },
    [filter.ledgerAccountDisplayIds, setFilter]
  );

  const removeCard = useCallback(
    (id: string) => {
      setFilter({
        cardIds: filter.cardIds.filter((x) => x !== id),
      });
    },
    [filter.cardIds, setFilter]
  );

  const removeRecurrence = useCallback(
    (period: RecurrencePeriod | undefined) => {
      setFilter({
        recurrences: filter.recurrences?.filter((x) => x !== period),
      });
    },
    [filter.recurrences, setFilter]
  );

  const removeVendor = useCallback(
    (id: string) => {
      setFilter({
        vendorIds: filter.vendorIds.filter((x) => x !== id),
      });
    },
    [filter.vendorIds, setFilter]
  );

  const impactfulTransactionsMetadata = useImpactfulTransactions({
    queryVariables,
    filter,
    uncategorizedPermaKeys,
    transactions,
    loadingTransactions,
    loadingSearchOptions,
    transactionsByPage,
  });

  const context = useMemo(
    () => ({
      open,
      transactions,
      totals,
      setActiveTransaction,
      activeTransactionId,
      setActiveTransactionId,
      sortOptions,
      setSortOptions,
      fetchNextPage,
      hasMore,
      refetch,
      loading,
      companyId,
      filter,
      filterCount,
      setFilter,
      setFilterCount,
      resetFilter,
      cards,
      cardsById,
      categories,
      categoriesByPermaKey,
      removeCategory,
      removeCategorizableAccount,
      removeCard,
      removeRecurrence,
      removeAccount,
      removeClassFilters,
      removeVendor,
      accountsById,
      institutions,
      classificationOptions,
      selectedCategories,
      selectedCategorizableAccounts,
      selectedVendors,
      selectedClassFilters,

      // date filters
      rangePresets,
      allTimeRangeCalled,
      allTimeRangeLoading,
      allTimeRange,
      fetchAllTimeRange,

      impactfulTransactionsMetadata,
      queryVariables,
      transactionsByPage,
    }),
    [
      transactions,
      totals,
      setActiveTransaction,
      activeTransactionId,
      setActiveTransactionId,
      sortOptions,
      fetchNextPage,
      hasMore,
      refetch,
      loading,
      companyId,
      filter,
      filterCount,
      setFilter,
      setFilterCount,
      resetFilter,
      cards,
      cardsById,
      categories,
      categoriesByPermaKey,
      removeCategory,
      removeCategorizableAccount,
      removeCard,
      removeRecurrence,
      removeAccount,
      removeClassFilters,
      removeVendor,
      accountsById,
      institutions,
      selectedCategories,
      selectedCategorizableAccounts,
      selectedVendors,
      selectedClassFilters,
      classificationOptions,
      rangePresets,
      allTimeRange,
      allTimeRangeCalled,
      allTimeRangeLoading,
      fetchAllTimeRange,
      impactfulTransactionsMetadata,
      queryVariables,
      transactionsByPage,
    ]
  );

  return (
    <TransactionsPageContext.Provider value={context}>{children}</TransactionsPageContext.Provider>
  );
};

export const useTransactionsPage = () => {
  const context = React.useContext(TransactionsPageContext);
  if (context === null) {
    throw new Error("TransactionsPageContext not found");
  }
  return context;
};
