import React from "react";

import { Tooltip, IconButton } from "@puzzle/ui";
import { styled } from "@puzzle/theme";
import {
  Hourglass,
  Processing,
  ProcessFailed,
  Receipt,
  BillFile,
  OpenBox,
  Schedule,
} from "@puzzle/icons";

import { BasicTransactionFragment } from "./graphql.generated";
import { LedgerEventStatus } from "graphql/types";

const statusMessage = {
  pending:
    "This transaction is currently pending on at least one basis and not yet posted to the general ledger or reflected in financial statements.",
  processing:
    "Updates to this transaction are currently being processed and may not yet be reflected in the general ledger or the financial statements.",
  failed:
    "Posting or updating this transaction failed. Please contact customer support for assistance.",
};

const tooltipProps = {
  side: "bottom",
  align: "start",
  alignOffset: 4,
  arrow: false,
} as const;

const IconText = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "$1",
  cursor: "pointer",
});

const checkEventStatus = (accrualStatus: LedgerEventStatus, cashStatus: LedgerEventStatus) => {
  if (
    accrualStatus === LedgerEventStatus.Success &&
    cashStatus === LedgerEventStatus.NotApplicable
  ) {
    return true;
  }
  if (
    accrualStatus === LedgerEventStatus.NotApplicable &&
    cashStatus === LedgerEventStatus.Success
  ) {
    return true;
  }
  if (accrualStatus === LedgerEventStatus.Success && cashStatus === LedgerEventStatus.Success) {
    return true;
  }
  if (
    accrualStatus === LedgerEventStatus.NotApplicable &&
    cashStatus === LedgerEventStatus.NotApplicable
  ) {
    return true;
  }
  return false;
};

const PostingStatus = ({
  status,
  includeText,
}: {
  status: LedgerEventStatus;
  includeText: boolean;
}) => {
  return (
    <>
      {status === LedgerEventStatus.Pending && (
        <Tooltip content={statusMessage.pending} {...tooltipProps}>
          <IconText>
            <IconButton css={{ color: "$neutral500" }}>
              <Hourglass />
            </IconButton>
            {includeText && <>Pending</>}
          </IconText>
        </Tooltip>
      )}
      {status === LedgerEventStatus.Processing && (
        <Tooltip content={statusMessage.processing} {...tooltipProps}>
          <IconText>
            <IconButton css={{ color: "$neutral500" }}>
              <Processing />
            </IconButton>
            {includeText && <>Processing</>}
          </IconText>
        </Tooltip>
      )}
      {status === LedgerEventStatus.Failed && (
        <Tooltip content={statusMessage.failed} {...tooltipProps}>
          <IconText>
            <IconButton
              css={{
                color: "$red600",
                "&:hover": {
                  color: "$red500",
                },
              }}
            >
              <ProcessFailed />
            </IconButton>
            {includeText && <>Failed</>}
          </IconText>
        </Tooltip>
      )}
    </>
  );
};

export const TransactionStatus = ({ transaction }: { transaction: BasicTransactionFragment }) => {
  const accrualStatus = transaction.eventViews.accrual.status;
  const cashStatus = transaction.eventViews.cash.status;

  const isPosted = checkEventStatus(accrualStatus, cashStatus);

  const status = isPosted
    ? LedgerEventStatus.Success
    : accrualStatus === LedgerEventStatus.Failed || cashStatus === LedgerEventStatus.Failed
      ? LedgerEventStatus.Failed
      : accrualStatus === LedgerEventStatus.Pending || cashStatus === LedgerEventStatus.Pending
        ? LedgerEventStatus.Pending
        : LedgerEventStatus.Processing;

  return (
    <>
      {!isPosted ? (
        <PostingStatus status={status} includeText={false} />
      ) : (
        <>
          {transaction.hasDocumentation && (
            <Tooltip content="Attached documentation" {...tooltipProps} sideOffset={18}>
              <IconButton css={{ color: "$neutral500" }}>
                <Receipt />
              </IconButton>
            </Tooltip>
          )}

          {transaction.linkedBills.length > 0 && (
            <Tooltip content="Matched to bill" {...tooltipProps} sideOffset={18}>
              <IconButton css={{ color: "$neutral500" }}>
                <BillFile />
              </IconButton>
            </Tooltip>
          )}

          {transaction.detail.capitalizable && (
            <Tooltip
              content="Flagged for capitalization | You can use a manual journal entry to capitalize by reclassifying the amount from an expense to a fixed asset."
              {...tooltipProps}
              sideOffset={18}
            >
              <IconButton css={{ color: "$neutral500" }}>
                <OpenBox />
              </IconButton>
            </Tooltip>
          )}

          {transaction.detail.schedule && (
            <Tooltip content="Revenue Schedule" {...tooltipProps} sideOffset={18}>
              <IconButton css={{ color: "$neutral500" }}>
                <Schedule />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </>
  );
};
