import React from "react";
import { Button, Tooltip } from "@puzzle/ui";
import {
  useExtraTransactionState,
  useUpdateCustomer,
  useUpdateVendor,
} from "components/dashboard/Transactions/hooks/useSingleTransaction";
import { CustomerSelect } from "components/dashboard/Transactions/UpdateField/CustomerSelect";
import { VendorSelect } from "../vendors/VendorSelect";
import { S } from "@puzzle/theme";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import { zIndex } from "@puzzle/utils";
import { TransactionRowData } from "components/dashboard/Transactions/shared";
import { getCounterpartyLabel, isRevenueCategory } from "./VendorCell.utils";
import { noVendor } from "../vendors/VendorSearch";

export const VendorCell = ({ rowData }: { rowData?: TransactionRowData }) => {
  // we care if the parent transaction is revenue and whether or not the
  // split is a revenue split
  const isRevenueSplit = isRevenueCategory(rowData?.category);
  const isRevenueTransaction = isRevenueCategory(rowData?.transaction.detail.category);
  const isParentTransaction = rowData?.id === rowData?.transaction.id;

  // new splits ui will allow setting vendor/customer on splits
  // otherwise everything will impact the parent transaction
  const isNewSplitsEnabled = isPosthogFeatureFlagEnabled(FeatureFlag.SplitsV2);

  const { canEdit } = useExtraTransactionState(rowData?.transaction);

  // if new splits are enabled, we can set vendor/customer on splits
  // otherwise everything will impact the parent transaction
  // so the split id on the update will be undefined until the
  // new feature is enabled
  const splitIdForUpdates = isNewSplitsEnabled && !isParentTransaction ? rowData?.id : undefined;

  const updateCustomer = useUpdateCustomer(rowData?.transaction, splitIdForUpdates);
  const updateVendor = useUpdateVendor(rowData?.transaction, splitIdForUpdates);

  // these are the labels prior to the new splits ui
  const transactionDetail = rowData?.transaction.detail;
  const vendorLabel = transactionDetail?.vendor?.name ?? noVendor.name;
  const customerLabel = transactionDetail?.customer?.name ?? "No customer";
  let counterpartyLabel = isRevenueTransaction ? customerLabel : vendorLabel;

  if (isNewSplitsEnabled) {
    // getting the lave for counterparty is more complex with the new splits ui
    // so we'll use the helper function to get the correct label
    counterpartyLabel = getCounterpartyLabel(rowData);
  }

  const isParentWithSplits = Boolean(rowData?.transaction.splits?.length) && isParentTransaction;

  // in the old ui only can edit would determine whether or not edit mode was enabled
  // now we have to check if the parent transaction has splits and if the new splits ui is enabled
  const showStaticCounterParty = (isParentWithSplits && isNewSplitsEnabled) || !canEdit;

  if (showStaticCounterParty) {
    return (
      <>
        {isParentWithSplits && isNewSplitsEnabled ? (
          <Tooltip style={{ zIndex: 9999 }} content={`Vendor/customer can be set on the splits`}>
            <div style={{ marginLeft: S["1"] }}>{counterpartyLabel}</div>
          </Tooltip>
        ) : (
          <div style={{ marginLeft: S["1"] }}>{counterpartyLabel}</div>
        )}
      </>
    );
  }

  if (isRevenueSplit) {
    return (
      <CustomerSelect
        key="customer-select"
        value={rowData?.customer || null}
        onSelect={updateCustomer}
        customTrigger={
          <Button size="compact" variant="minimal" css={{ padding: "$0 $1" }}>
            {counterpartyLabel}
          </Button>
        }
      />
    );
  }
  return (
    <VendorSelect
      key="vendor-select"
      value={rowData?.vendor || null}
      onSelect={updateVendor}
      menuCss={{ zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("menu") : "auto" }}
      customTrigger={
        <Button
          size="compact"
          variant="minimal"
          css={{
            padding: "$0 $1",
            "&:focus": { backgroundColor: "transparent", color: "$neutral300" },
          }}
        >
          {counterpartyLabel}
        </Button>
      }
    />
  );
};
