import React from "react";

import { Stack, Text } from "@puzzle/ui";
import { UpgradeButton } from "./UpgradeButton";

type MonthlyExpensesExceededGateBannerProps = {
  subtext: string;
};

export const MonthlyExpensesExceededBanner = ({
  subtext,
}: MonthlyExpensesExceededGateBannerProps) => {
  return (
    <Stack
      css={{
        gap: "$2h",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Text variant="headingL" color="neutral200">
        Monthly Expense Allowance Exceeded
      </Text>
      <Text variant="bodyM" color="neutral400">
        It looks like you've exceeded the monthly expense allowance of $15,000 for the Formation
        plan.
      </Text>
      <Text variant="bodyM" color="neutral400">
        To {subtext}, please upgrade to a paid plan.
      </Text>
      <UpgradeButton />
    </Stack>
  );
};

export const MonthlyExpensesExceededBannerInline = ({
  subtext,
}: MonthlyExpensesExceededGateBannerProps) => {
  return (
    <Stack css={{ padding: "$2", gap: "$2h", borderRadius: "$1", backgroundColor: "$black" }}>
      <Text variant="headingL" color="neutral200">
        Monthly Expense Allowance Exceeded
      </Text>
      <Text variant="bodyM" color="neutral400">
        It looks like you've exceeded the monthly expense allowance of $15,000 for the Formation
        plan. To {subtext}, please upgrade to a paid plan.
      </Text>
      <UpgradeButton />
    </Stack>
  );
};
